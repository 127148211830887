import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useDebounce from '../../hooks/useDebounce';
import { ApiResponse } from '../../models/ApiResponse';
import { ClientFormDefault } from '../../models/ClientFormDefaults';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import ClientTemplateModuleService from '../../services/ClientTemplateModuleService';
import { nextTick } from '../../utils/ReactUtils';
import { SearchInput } from '../shared/form-control/SearchInput';
import { Heading, HeadingSize } from '../shared/text/Heading';
import FormDefaultsList from './OrgFormDefaultsList';

const OrgFormDefaults: FC = () => {
  const currentClient = useRecoilValue(currentClientAtom);
  const [loading, setLoading] = useState(false);
  const pageContentRef = useRef<HTMLDivElement>(null);
  const [clientFormDefaults, setClientFormDefaults] = useState<ApiResponse<ClientFormDefault[]> | null>(null);
  const [sortBy, setSortBy] = useState('+code');
  const [currentPage, setCurrentPage] = useState(1);
  const [formSearchPhrase, setFormSearchPhrase] = useState('');
  const debouncedSearchTerm = useDebounce(formSearchPhrase, 500);
  const { t } = useTranslation('organisation');

  const onSortBy = (expression: string) => {
    setClientFormDefaults(null);
    setCurrentPage(1);
    setSortBy(expression);
  };

  const formsFilter = useMemo(
    () => ({
      latestOnly: true,
      searchTerm: debouncedSearchTerm,
      sortBy: sortBy,
      pageSize: 15,
      pageNumber: currentPage,
    }),
    [currentPage, debouncedSearchTerm, sortBy],
  );

  const filterForms = useCallback(() => {
    const scrollTop = pageContentRef.current?.scrollTop || 0;
    if (currentClient) {
      setLoading(true);
      new ClientTemplateModuleService(currentClient.id).getAllTemplateFormDefaults(formsFilter).then((res) => {
        setClientFormDefaults((prev) => {
          const formsPaged = { ...res };
          if (prev?.data && formsFilter.pageNumber > 1) {
            formsPaged.data = [...prev.data, ...res.data] as ClientFormDefault[];
          }
          return formsPaged;
        });
        setLoading(false);

        nextTick(() => {
          pageContentRef.current?.scrollTo({ top: scrollTop });
        });
      });
    }
  }, [currentClient, formsFilter]);

  useEffect(() => {
    filterForms();
  }, [filterForms]);

  const onUpdated = (clientFormDefault: ClientFormDefault) => {
    setClientFormDefaults((prev) => {
      if (prev) {
        return {
          ...prev,
          data: [...prev.data.map((x) => (x.templateForm.id === clientFormDefault.templateForm.id ? clientFormDefault : x))],
        };
      }
      return prev;
    });
  };

  return (
    <div className="flex h-full flex-col pt-6" ref={pageContentRef}>
      <div className="flex justify-between">
        <Heading size={HeadingSize.H3}>{t('tabs.form-defaults')}</Heading>
        <div className="flex justify-between gap-4">
          <div className="w-80">
            <SearchInput
              placeholder="Search"
              value={formSearchPhrase}
              onChange={(e) => {
                setCurrentPage(1);
                setFormSearchPhrase(e.target.value);
              }}
              data-cy="search-template"
            />
          </div>
        </div>
      </div>
      <div className="bg-background-1 -mx-6 h-full p-8 pt-4">
        <div className="flex-grow pt-4">
          {clientFormDefaults && (
            <FormDefaultsList
              clientFormDefaultsPaged={clientFormDefaults}
              sortBy={sortBy}
              onSort={onSortBy}
              onLoadMore={setCurrentPage}
              isLoading={loading}
              onUpdated={onUpdated}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrgFormDefaults;
