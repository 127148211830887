/* eslint-disable @typescript-eslint/no-explicit-any */
import { Children } from 'react';
import { FCWithChildren } from '../../../types/FCWithChildren';
import { ContextMenuItem } from '../ContextMenu';
import { ChevronType } from '../icon/ChevronIcon';
import DataRow from './DataRow';

type CollapsableDataRowProps = {
  contextMenuItems?: ContextMenuItem[];
  title?: string;
  onClick: () => void;
  open: boolean;
};

const Header: FCWithChildren = (props) => {
  return <>{props.children}</>;
};

const Content: FCWithChildren = (props) => {
  return <>{props.children}</>;
};

type CollapsableDataRowType<P = Record<string, unknown>> = FCWithChildren<P> & {
  Header: typeof Header;
  Content: typeof Content;
};

const CollapsableDataRow: CollapsableDataRowType<CollapsableDataRowProps> = (props) => {
  const { contextMenuItems, title, children, onClick, open } = props;

  const childrenArr = Children.toArray(children);
  const header: any = childrenArr.find(({ type }: any) => type === Header);
  const content: any = childrenArr.filter(({ type }: any) => type === Content);

  return (
    <>
      <DataRow title={title} onClick={onClick} contextMenuItems={contextMenuItems} chevronStyle={open ? ChevronType.UP : ChevronType.DOWN}>
        {header}
      </DataRow>
      {open && <div className="border-gray-5 relative border-2 border-t-0 bg-white p-4">{content}</div>}
    </>
  );
};

CollapsableDataRow.Header = Header;
CollapsableDataRow.Content = Content;

export default CollapsableDataRow;
