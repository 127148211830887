import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSectionConfiguration } from '../../models/ClientFormDefaults';
import { ClientFormUser } from '../../models/ClientFormUser';
import { ClientFormUserRoleValues } from '../../models/ClientFormUserRoles';
import { FormSection } from '../../models/Form';
import User from '../../models/User';
import LanguageUtils from '../../utils/LanguageUtils';
import FormOwnership from '../ownership/FormOwnership';
import { OwnershipDisplayType } from '../ownership/OwnershipProps';
import Tooltip from '../shared/Tooltip';

type FormSectionDefaultsProps = {
  section: FormSection;
  configuration: FormSectionConfiguration | null;
  clientUsers: User[];
  onUsersChange: (configuration: FormSectionConfiguration) => void;
  onCreatorRoleChange: (configuration: FormSectionConfiguration) => void;
  requiresApproval: boolean;
  requiresValidation: boolean;
};

const FormSectionDefaults: FC<FormSectionDefaultsProps> = (props) => {
  const { section, configuration, clientUsers, onUsersChange, onCreatorRoleChange, requiresApproval, requiresValidation } = props;
  const { t } = useTranslation(['organisation', 'common']);
  const users = useMemo((): ClientFormUser[] => {
    if (configuration && clientUsers.length) {
      const users = Object.entries(configuration?.users || []).map(([userId, role]) => {
        const clientUser = clientUsers.find((user) => user.id && user.id === userId);
        return { ...(clientUser as User), role, formSectionId: section.id };
      });
      return users;
    }
    return [];
  }, [clientUsers, configuration, section.id]);

  const handleUsersChange = (users: ClientFormUser[]) => {
    const sectionConfiguration = {
      ...configuration,
      id: section.id,
      users: Object.assign({}, ...users.map((x) => ({ [x.id || '']: x.role }))),
    } as FormSectionConfiguration;
    onUsersChange(sectionConfiguration);
  };

  const handleCreatorRoleChange = (role: ClientFormUserRoleValues | null) => {
    const sectionConfiguration = {
      ...configuration,
      id: section.id,
      creatorRole: role,
    } as FormSectionConfiguration;
    onCreatorRoleChange(sectionConfiguration);
  };

  const sectionTitle = useMemo(() => {
    return LanguageUtils.getTranslation('title', section.translations || {});
  }, [section.translations]);

  return (
    <div className="flex w-48 flex-col items-center px-2 text-center">
      <Tooltip text={sectionTitle} truncatedTextMode>
        {(tooltip) => {
          return (
            <div className="w-48 truncate" {...tooltip}>
              {sectionTitle}
            </div>
          );
        }}
      </Tooltip>

      <FormOwnership
        formStepId={section.id}
        users={users}
        size={OwnershipDisplayType.Tiny}
        modalHeading={t('organisation:forms-defaults.default-users-heading')}
        onUsersChange={handleUsersChange}
        onCreatorRoleChange={handleCreatorRoleChange}
        creatorRole={configuration?.creatorRole}
        requiresApproval={requiresApproval}
        requiresValidation={requiresValidation}
        shouldValidateRoles={false}
      />
    </div>
  );
};

export default FormSectionDefaults;
